import React from 'react'
import styled from 'styled-components/macro'

import { Section, Container, Row, H1 } from '../Components'

const techs = [
  {
    name: 'javascript',
    color: 'primary',
  },

  {
    name: 'react',
    color: 'light',
  },

  {
    name: 'redux',
    color: 'success',
  },

  {
    name: 'node.js',
    color: 'light',
  },

  {
    name: 'next.js',
    color: 'info',
  },

  {
    name: 'graphQL',
    color: 'warning',
  },

  {
    name: 'web3.js',
    color: 'success',
  },

  {
    name: 'ethereum',
    color: 'light',
  },



  {
    name: 'SEO',
    color: 'success',
  },


  {
    name: 'php',
    color: 'success',
  },

  {
    name: 'postgreSQL',
    color: 'primary',
  },
  {
    name: 'mySQL',
    color: 'secondary',
  },

  // {
  //   name: 'photoshop',
  //   color: 'primary',
  // },

  {
    name: 'git',
    color: 'secondary',
  },

  {
    name: 'python',
    color: 'primary',
  },

  {
    name: 'numpy',
    color: 'success',
  },

  {
    name: 'pandas',
    color: 'danger',
  },

  {
    name: 'docker',
    color: 'warning',
  },
]

const Tech = styled.button.attrs({
  className: props => `btn btn-outline-${props.color}`,
})`
  margin-left: 0.5em;
  margin-top: 0.5em;
`

export default () => (
  <Section light>
    <Container>
      <Row>
        <div className="col-md-12">
          <H1> #techs </H1>
          {techs.map(tech => (
            <Tech color={tech.color}>{tech.name}</Tech>
          ))}
        </div>
      </Row>
    </Container>
  </Section>
)
