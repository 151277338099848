import React from 'react'
import { createGlobalStyle, ThemeProvider } from 'styled-components/macro'
import * as theme from 'styles/theme.js'
import { Helmet } from 'react-helmet'

import Footer from '../components/Footer'

const GlobalStyles = createGlobalStyle`
    html {    
        height: 100%;
    }
    body {
        margin: 0;
        padding: 0;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-color: #3C3C3C;
    }

    .flex-equal > * {
        -ms-flex: 1;
        flex: 1;
    }
    @media (min-width: 768px) {
        .flex-md-equal > * {
        -ms-flex: 1;
        flex: 1;
        }
    }

    .overflow-hidden { 
        overflow: hidden; 
    }


    .bd-placeholder-img {
        font-size: 1.125rem;
        text-anchor: middle;
    }

    @media (min-width: 768px) {
        .bd-placeholder-img-lg {
            font-size: 3.5rem;
        }
    }

    ul {
        margin-left: 0px !important;
    }
`

export default ({ children }) => {
  const seo = {
    title: 'ernane: Web, Blockchain, Design',
    description:
      'Skilled Software Engineer - JavaScript: React/Redux, Node.js. Ethereum protocol, SQL Databases. Enthusiastic about how Blockchain and Cryptocurrencies can be used to build a better society.',
    url: 'http://ernane.me', // No trailing slash allowed!
    image: '/img/thumb.png', // Path to your image you placed in the 'static' folder
    twitterUsername: '@ernaneluis',
  }

  return (
    <ThemeProvider theme={theme}>
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{seo.title}</title>
          <link rel="canonical" href={seo.url} />

          <meta name="description" content={seo.description} />
          <meta name="image" content={seo.image} />

          <meta property="og:url" content={seo.url} />
          <meta property="og:type" content="article" />
          <meta property="og:title" content={seo.title} />
          <meta property="og:description" content={seo.description} />
          <meta property="og:image" content={seo.image} />

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:creator" content={seo.twitterUsername} />
          <meta name="twitter:title" content={seo.title} />
          <meta name="twitter:description" content={seo.description} />
          <meta name="twitter:image" content={seo.image} />
        </Helmet>
        <GlobalStyles />
        {children}
        <Footer />
      </>
    </ThemeProvider>
  )
}
