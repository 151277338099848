import React from 'react'
import styled from 'styled-components/macro'

const Section = styled.section.attrs({
  className: 'p-5',
})`
  background-color: ${props =>
    props.dark ? '#3C3C3C' : props.light ? '#424242' : ''};
`

const Container = styled.div.attrs({
  className: 'container',
})``

const Row = styled.div.attrs({
  className: 'row',
})``

const H1 = styled.h1`
  color: white;
  border-bottom: none;
`

const H2 = styled.h2`
  border-bottom: none;
`

export { Section, Container, Row, H1, H2 }
