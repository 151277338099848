import React from 'react'
import SyntaxHighlighter from 'react-syntax-highlighter'
import { anOldHope } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import styled from 'styled-components/macro'

const Container = styled.div.attrs({
  className: 'container-fluid',
})`
  z-index: 2;
`

const Row = styled.div.attrs({
  className: 'row text-center',
})``

const Code = styled.div.attrs({
  className: 'col-12 col-md-11 mx-auto',
})`
  padding-top: 4vw;
  padding-bottom: 4vw;
`

const Text = styled.div.attrs({
  className: 'col-12 col-md-8 mx-auto',
})`
  padding-top: 4vw;
  padding-bottom: 8vw;
`

const Grid = styled.div`
  position: absolute;
  right: -20%;
  bottom: 0;
  left: -20%;
  height: 256px;

  z-index: 0;
  background-image: linear-gradient(#333 0px, #333 1px, transparent 2px),
    linear-gradient(90deg, #333 0px, #333 1px, transparent 2px);
  background-position: center 0;
  background-size: 24px 24px;

  -webkit-transform: rotateX(70deg);
  transform: rotateX(70deg);
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;

  animation: grid 1s linear infinite;

  &:before {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    left: 0;
    height: 80%;
    content: '';
    background-image: linear-gradient(#212121, transparent);
  }

  @-webkit-keyframes grid {
    to {
      background-position: center -24px;
    }
  }

  @keyframes grid {
    to {
      background-position: center -24px;
    }
  }
`

const Section = styled.section`
  z-index: 1;
  position: relative;
  overflow: hidden;
  perspective: 500px;
  display: flex;

  background: #212121;
  color: white;
`

const string = `"Hello, world!"
This is a great "string" but here are better ones:
const name = "My name is Ernane Luis but call me Luis"
let Iam = "M.Sc 🖥 Computer Scientist"
let focusedOn = ["🌐 Web Development", "🅱️ Blockchain", "🎨 Design"]
`

const Button = styled.a.attrs({
  className: 'btn mb-3',
})``

const buttons = [
  {
    label: 'Linkedin',
    href: 'https://www.linkedin.com/in/ernaneluis/',
    color: 'btn-outline-primary',
  },
  {
    label: 'Github',
    href: 'https://github.com/ernaneluis',
    color: 'btn-outline-secondary',
  },
  {
    label: 'Twitter',
    href: 'http://twitter.com/ernaneluis/',
    color: 'btn-outline-info',
  },
  {
    label: 'Telegram',
    href: 'http://t.me/ernaneluis',
    color: 'btn-outline-light',
  },
  {
    label: 'StackExchange',
    href: 'https://ethereum.stackexchange.com/users/17896/ernane-luis-paixão',
    color: 'btn-outline-warning',
  },
]

const SubText = styled.p`
  text-align: justify;
  font-size: 1rem;
  color: grey;
`

const Hero = () => (
  <Section>
    <Container>
      <Row>
        <Code>
          <SyntaxHighlighter
            language="javascript"
            style={anOldHope}
            customStyle={{
              background: 'transparent',
              fontSize: '2vw',
              lineHeight: '4vw',
            }}
          >
            {string}
          </SyntaxHighlighter>
        </Code>
      </Row>

      <Row>
        <Text>
          <div className="row mb-3">
            <div className="col-auto">
              <SubText>
                I'm always interested in collaborations with developers,
                designers and crypto projects around the world. Feel free to
                drop me a line if you have any questions or project inquiries.
              </SubText>
            </div>
          </div>
          <div className="row">
            {buttons.map(button => (
              <div className="col-auto">
                <Button
                  href={button.href}
                  className={button.color}
                  target="_blank"
                >
                  <span>{button.label}</span>
                </Button>
              </div>
            ))}
            <div className="col-auto">
              👍 Contact at any time{' '}
              <a href="mailto:hi@ernane.me">hi@ernane.me</a>
            </div>
          </div>
        </Text>
      </Row>
    </Container>
    <Grid />
  </Section>
)

export default Hero
