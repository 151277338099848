import React from 'react'

import Layout from '../components/Layout'

import Education from '../components/index/Education'
import Hero from '../components/index/Hero'
import Projects from '../components/index/Projects'
import Techs from '../components/index/Techs'

export default () => (
  <Layout>
    <Hero />
    <Projects />
    <Education />
    <Techs />
  </Layout>
)
