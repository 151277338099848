import React from 'react'
import styled from 'styled-components/macro'

import { Section, Container, Row, H1, H2 } from '../Components'

const educations = [
  {
    name: 'University of Bonn',
    date: '2015-2017',
    description: 'Degree Master of Science (M.S.) in Computer Science',
  },
  {
    name: 'Eötvös Loránd Tudományegyetem (ELTE)',
    date: '2013-2014',
    description: "Exchange student for Bachelor's degree in Computer Science",
  },
  {
    name: 'Universidade Federal do Ceará',
    date: '2010-2015',
    description: "Bachelor's degree in Computer Science",
  },
]

const Date = styled.b`
  color: dimgray;
`

const Name = styled.small`
  color: #4fb4d7;
`

const Description = styled.p`
  color: darkgrey;
`

export default () => (
  <Section dark>
    <Container>
      <H1> #education </H1>
      {educations.map(education => (
        <Row>
          <div className="col-md-12">
            <H2>
              <Date>{education.date}</Date> <Name>{education.name}</Name>
            </H2>
            <Description>{education.description}</Description>
          </div>
        </Row>
      ))}
    </Container>
  </Section>
)
