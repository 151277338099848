import React from 'react'
import styled from 'styled-components/macro'

import { Section, Container, Row, H1, H2 } from '../Components'

const projects = [
  {
    name: 'Tokeny',
    date: '2019-Present',
    description:
      'Tokeny Solutions enables firms to realise fast onboarding, cost-efficient management and rapid transferability of private market securities.',
    url: 'https://tokeny.com/',
    img: [''],
    types: ['react', 'vue', 'node.js', 'express.js', 'ethereum', 'web3.js'],
  },
  {
    name: 'alertapromo.com',
    date: '2020-Present',
    description:
      'Created, developed and design a coupons/deals finder for Brazilian market',
    url: 'https://alertapromo.com/',
    img: [''],
    types: ['react', 'nextjs', 'node.js', 'graphql', 'tailwindcss'],
  },
  {
    name: 'Clam Island',
    date: '2021-2021',
    description:
      'Help implement integration to web3',
    url: 'http://clamisland.fi/',
    img: [''],
    types: ['react', 'web3'],
  },
  {
    name: 'Gitcoin',
    date: '2018-2018',
    description:
      'Contributor #62 to gitcoin plataform',
    url: 'https://github.com/gitcoinco/web/graphs/contributors',
    img: [''],
    types: ['react', 'css', 'design', 'django'],
  },
  {
    name: 'TxHash',
    date: '2017-2018',
    description:
      'Design and Developed the website, contributed for the integration of the app into third-party services.',
    url: 'https://txhash.com/',
    img: [''],
    types: ['react', 'nodejs', 'web3'],
  },
  {
    name: 'Starbase',
    date: '2016-2019',
    description:
      'Design and create website, develop plataform for descentralized crowdsourcing',
    url: 'http://starbase.co/',
    img: [''],
    types: ['nextjs', 'node.js', 'react', 'design', 'web3'],
  },
  {
    name: 'Pickcells',
    date: '2019',
    description:
      'Design and create website for Brazilian health startup Pickcells',
    url: 'http://www.pickcells.bio/?lang=en',
    img: [''],
    types: ['web', 'node.js', 'ejs', 'design'],
  },
  {
    name: 'Carthoreum',
    date: '2018-In progress',
    description:
      'A Descentralized notary web all built on Etherem where accepts multiple signers and store signed documents at IPFS',
    url: 'https://github.com/ernaneluis/carthoreum',
    img: [''],
    types: [
      'web',
      'opensource',
      'ethereum',
      'react',
      'redux',
      'web3.js',
      'IPFS',
    ],
  },
  {
    name: 'Bitmask',
    date: '2018-In progress',
    description: 'A light Bitcoin wallet built on react/redux',
    url: 'https://github.com/ernaneluis/bitmask',
    img: [''],
    types: ['web', 'opensource', 'bitcoin', 'react', 'redux'],
  },
  {
    name: 'MyEtherWallet Desktop',
    date: '2017',
    description:
      'A Desktop wrapper of the MyEtherWallet ethereum wallet using Electron',
    url: 'https://github.com/ernaneluis/desktop-mew',
    img: [''],
    types: ['web', 'opensource', 'ethereum'],
  },
  {
    name: 'Graph Dynamics',
    date: '2017-2018',
    description: [
      'Collaborate in the development of a python library to implement several algorithms for processing, machine learning and embedding of ',
      <a href="https://en.wikipedia.org/wiki/Graph_dynamical_system">
        dynamic graphs
      </a>,
    ],
    url: 'https://github.com/ernaneluis/graph-dynamics',
    img: [''],
    types: ['python', 'pandas', 'numpy'],
  },
  {
    name: 'Ponto Saude',
    date: '2015-2016',
    description:
      'A personal project, helps users to find the nearest hospital or medical clinic near them. Have detailed information like: Services provided, Specialized Services, address and others.',
    url: 'https://github.com/ernaneluis/pontosaude-app/',
    img: [''],
    types: ['web', 'backend', 'mobile', 'hybrid', 'ionic2'],
  },
  {
    name: 'SportNews',
    date: '2015-2016',
    description:
      'Help users to follow exclusive news from Sport Club of Recife on your smartphone. SportNews allows permanent connection and interactivity between the club and the red-black nation. It is Sport online and in real time. Everything that happens on the day of all the modalities of the team.',
    url: 'https://itunes.apple.com/in/app/sportnews/id995509923?mt=8',
    img: [''],
    types: ['mobile', 'ios'],
  },
  {
    name: 'LikeStyle',
    date: '2014-2015',
    description:
      'A very fun fashion app that makes life easier for fashion lovers, you can publish a photo and quickly receive fashion reviews and also give opinions and inspiration from the publications of other users. There are thousands of users. Provide and receive opinions on Looks, makeup, accessories, hairstyles, etc.',
    url: 'https://facebook.com/likestyleapp/',
    img: [''],
    types: ['mobile', 'ios'],
  },
  {
    name: 'Primeiramão',
    date: '2014-2014',
    description:
      'Primeiramão is the largest classified newspaper in Brazil. Here you will find the best offers of cars, real estate, computer articles, products for your home and much more. You can buy and sell much more easily directly from your device.',
    url: 'http://primeiramao.band.com.br',
    img: [''],
    types: ['mobile', 'ios'],
  },
  {
    name: 'Runner’s World Planes de Entrenamiento',
    date: '2014-2014',
    description:
      "Whatever your goal, you are at your fingertips with the Runner's World Training Plans app. Any type of rider will find a plan to suit you (initiation, intermediate or advanced) for your personal objective: from running 30 'without stopping to finish a marathon or the best mark in the 42 kilometers and 195 meters.",
    url:
      'https://itunes.apple.com/es/app/runners-world-planes-entrenamiento/id850274521?mt=8',
    img: [''],
    types: ['mobile', 'ios'],
  },
]

const Date = styled.b`
  color: #c0c5ce;
`
const Description = styled.p`
  color: darkgrey;
`

const Name = styled.a`
  color: #4fb4d7;
`

const Types = styled.button.attrs({
  className: 'btn btn-outline-light',
})`
  margin-left: 0.5em;
  margin-top: 0.5em;
`

export default () => (
  <Section light>
    <Container>
      <H1> #projects </H1>
      <Row>
        {projects.map(project => (
          <div className="col-md-4 mb-5">
            <H2>
              <Name
                href={project.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {project.name}
              </Name>
            </H2>

            <p>
              <Date>{project.date}</Date>
              <Description>{project.description}</Description>
            </p>

            <p>
              {project.types.map(type => (
                <Types>{type}</Types>
              ))}
            </p>
          </div>
        ))}
      </Row>
    </Container>
  </Section>
)
